<template>
  <div class="medications">
    <div class="medications-container">
      <div v-if="!medications.length" class="text-center mt-4">
        No medications
      </div>
      <div v-else>
        <div v-for="item in getData" :key="item">
          <div
            :id="'title ' + item.date"
            class="item-title"
            :class="item.date === currentDate ? 'item-title-today' : ''"
          >
            <h3 class="pa-2" style="font-size: 18px">{{ item.date }}</h3>
          </div>
          <v-list class="pa-0 ma-0">
            <div v-for="(element, index) in item.items" :key="element">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title :class="element.new ? 'ml-6' : 'ml-2'">
                    <div v-if="element.new" class="events">
                      <div></div>
                    </div>
                    {{ element.title }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn v-if="element.video" icon @click="goToVideo(element)">
                    <img
                      width="30px"
                      height="30px"
                      :src="require('../../assets/svg/play.svg')"
                    />
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="index < item.items.length - 1"
                :key="element"
              ></v-divider>
            </div>
          </v-list>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { calendar } from '../../sharedPlugin';
import _ from 'lodash';
import moment from 'moment';
export default {
  computed: {
    ...calendar.mapComputed([
      'selectedDate',
      'medications',
      'medicationsLoading'
    ]),
    getData() {
      let medications = this.medications;
      let data = [];
      medications.map((item) => {
        item.date = moment(item.end).format('YYYY-MM-DD');
        return item;
      });
      medications = _.groupBy(medications, 'date');
      Object.keys(medications).forEach((key) => {
        data.push({
          date: moment(key).format('dddd, MMMM DD, YYYY'),
          items: medications[key]
        });
      });
      return data.sort(function (a, b) {
        return moment(a.date) - moment(b.date);
      });
    },
    currentDate() {
      return moment().format('dddd, MMMM DD, YYYY');
    }
  },
  methods: {
    ...calendar.mapMethods(['getMedsAsync']),
    goToVideo(item) {
      if (item && item.video && item.video.link) {
        window.open(item.video.link);
      }
    }
  },
  created() {
    this.getMedsAsync();
  },
  mounted() {
    const date = moment(this.selectedDate).format('dddd, MMMM DD, YYYY');
    let element = document.getElementById(`title ${date}`);
    element?.scrollIntoView();
  }
};
</script>

<style lang="scss">
.medications {
  .medications-container {
    height: 92vh;
    overflow-y: scroll;
  }
  .item-action {
    font-size: 1rem;
    height: 36px;
    align-items: center;
  }
  .item-title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    height: 41px;
    background: #e7f4f5;
    color: #000;
    &-today {
      height: 41px;
      background: #d6366e;
      color: #fff;
    }
  }
  .events {
    height: 8px;
    top: 18px;
    left: 25px;
    position: absolute;
    text-align: left;
    white-space: pre;
    width: 100%;
    div {
      border-radius: 50%;
      display: inline-block;
      height: 8px;
      width: 8px;
      background-color: rgb(214, 54, 110);
    }
  }
}
</style>
